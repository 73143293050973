import React from 'react';
import {
  Heading,
  Grid,
  GridRow,
  GridCol,
  List,
  ListItem,
} from '@suncorp/styleguide-react-components';
import { useLaunchDarklyFlag } from '../../../utils/launchDarkly';
import { LAUNCH_DARKLY_PAYONLINE_OUTAGE_MESSAGE } from '../../../utils/constants/commonConstants';
const MaintenancePage = () => {
  const payOnlineOutageMsg = useLaunchDarklyFlag()[
    LAUNCH_DARKLY_PAYONLINE_OUTAGE_MESSAGE
  ];
  return (
    <section id="tech-error-section">
      <Grid>
        <GridRow>
          <GridCol>
            <Heading rank={1}>
              Oops! Looks like we couldn't process your request.
            </Heading>
          </GridCol>
        </GridRow>
        <GridRow className="sg-u-marginTop--x4">
          <GridCol>
            <Heading rank={2} className="sg-Type--heading5">
              Here's why
            </Heading>
            <List appearance="unordered">
              {payOnlineOutageMsg !== null && payOnlineOutageMsg !== '' ? (
                <ListItem>{payOnlineOutageMsg}</ListItem>
              ) : (
                <ListItem>
                  We are making some changes to improve your experience with us.
                  We apologize for any inconvenience this may cause. Please try
                  again later.
                </ListItem>
              )}
            </List>
          </GridCol>
        </GridRow>
      </Grid>
    </section>
  );
};

export default MaintenancePage;
