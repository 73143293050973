export const PolicyDetailsInitialState = {
  prnNumber: '',
  policyNumber: '',
  email: '',
  typeOfPrn: '',
  productDetails: [],
  inRenewal: false,
};

export const policyDetailsModel = {
  state: PolicyDetailsInitialState,
  reducers: {
    setPRNNumber(state, value) {
      return { ...state, prnNumber: value };
    },
    setPolicyNumber(state, value) {
      return { ...state, policyNumber: value };
    },
    setEmail(state, value) {
      return { ...state, email: value };
    },
    setTypeOfPrn(state, value) {
      return { ...state, typeOfPrn: value };
    },
    setProductDetails(state, value) {
      return { ...state, productDetails: value };
    },
    setInRenewal(state, value) {
      return { ...state, inRenewal: value };
    },
    resetPolicyDetails(state) {
      return { ...state, ...PolicyDetailsInitialState };
    },
  },
};

export default policyDetailsModel;
