import createModels from '../models';
import { init } from '@rematch/core';
import createRematchPersist from '@rematch/persist';
import createLoadingPlugin from '@rematch/loading';
import storage from 'redux-persist/lib/storage/session';
import reduxReset from 'redux-reset';
import { sessionTimeoutMiddleware } from '../middlewares/sessionTimeoutMiddleware';

export default function configureStore(sessionStorageKey) {
  const models = createModels();
  const persistPlugin = createRematchPersist({
    storage,
    key: sessionStorageKey,
    throttle: 100, // appears to be 1/100s of a second not 1/1000.
    blacklist: ['loading'],
  });

  const loadingPlugin = createLoadingPlugin({});

  const store = init({
    models,
    plugins: [persistPlugin, loadingPlugin],
    redux: {
      enhancers: [reduxReset()],
      middlewares: [sessionTimeoutMiddleware()],
      rootReducers: {
        SET_LAST_ACTIVE_TIME: (state, action) => ({
          ...state,
          sessionTimeout: { lastActiveTime: action.payload },
        }),
      },
    },
  });

  if (process.env.APP_ENV === 'local') {
    window.store = store;
  }

  return store;
}
