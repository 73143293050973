import navigationProgressModel from './navigationProgressModel';
import policyDetailsModel from './policyDetailsModel';
import cardAndPaymentDetailsModel from './cardAndPaymentDetailsModel';
import { helpersModel } from './helpersModel';
import transactionDetailsModel from './transactionDetailsModel';
import sessionTimeoutModel from './sessionTimeoutModel';

export const createModels = () => ({
  navigationProgress: navigationProgressModel,
  policyDetails: policyDetailsModel,
  cardAndPaymentDetails: cardAndPaymentDetailsModel,
  transactionDetails: transactionDetailsModel,
  helpers: helpersModel,
  sessionTimeout: sessionTimeoutModel,
});

export default createModels;
