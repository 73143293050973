import React, { useContext } from 'react';
import { BrandContext } from '../BrandContext';
import DefaultFooter from './components/DefaultFooter';
import ApiaFooter from './components/ApiaFooter';

const AppFooter = () => {
  const brandConfig = useContext(BrandContext);
  switch (brandConfig.brandName) {
    case 'APIA':
      return <ApiaFooter />;
    default:
      return <DefaultFooter />;
  }
};

export default AppFooter;
