import React from 'react';
import { ListItem } from '@suncorp/styleguide-react-components';
import * as PropType from 'prop-types';

const CurrentProgressStep = ({ displayText }) => (
  <ListItem
    className="sg-Progress-step sgjs-current sg-Progress-step--current sg-u-noMargin"
    data-position="current"
  >
    <span className="sg-u-visuallyhidden">Current step:</span>
    <span className="sg-Progress-text">{displayText}</span>
  </ListItem>
);

CurrentProgressStep.propTypes = {
  displayText: PropType.string,
};

export default CurrentProgressStep;
