import React, { useContext } from 'react';
import {
  FormQuestion,
  Link,
  TextInput,
} from '@suncorp/styleguide-react-components';
import * as PropType from 'prop-types';
import { useFormikContext } from 'formik';
import { BrandContext } from '../../organisms/BrandContext';

const PRNPolicyDetailsInput = ({ bindFieldToValidation, defaultValue }) => {
  const brandConfig = useContext(BrandContext);
  const veroBrand = brandConfig.brandName === 'VERO';
  const validationMessage = veroBrand
    ? 'Payment Reference Number'
    : 'policy number or Payment Reference Number';
  const { setFieldValue } = useFormikContext();
  const prnRegex = veroBrand
    ? new RegExp(/^[0-9]{16}/)
    : new RegExp(/^[a-zA-Z0-9]+$/);
  const maxLength = veroBrand ? 16 : 20;

  return (
    <>
      <FormQuestion
        id="prn-policy-number-question"
        data-testid="prn-policy-number-question"
        name="prnPolicyNumber"
        label={
          veroBrand
            ? 'Payment Reference Number (PRN)'
            : 'Policy number or Payment Reference Number (PRN)'
        }
        className="sg-u-noMarginBottom"
        bindFieldToValidation={bindFieldToValidation}
        validations={[
          {
            rule: (value) => !!value,
            message: `Please enter your ${validationMessage}`,
          },
          {
            rule: (value) => prnRegex.test(value),
            message: `Please enter a valid ${validationMessage}`,
          },
        ]}
      >
        <TextInput
          id="prn-policy-number-input"
          data-testid="prn-policy-number-input"
          maxLength={maxLength}
          className="sg-u-width10of10"
          defaultValue={defaultValue}
          onChange={(e) => setFieldValue('prnPolicyNumber', e.target.value)}
        />
      </FormQuestion>
      {!veroBrand && (
        <Link
          href={brandConfig.links.policyFinderLink}
          target="_blank"
          className="sg-Type--small"
        >
          Forgot your policy number?
        </Link>
      )}
    </>
  );
};

PRNPolicyDetailsInput.propTypes = {
  bindFieldToValidation: PropType.func,
  defaultValue: PropType.string,
};

export default PRNPolicyDetailsInput;
