import { callPolicyDetailsApi } from '../apis/policyDetailsApis';

export const CardAndPaymentDetailsInitialState = {
  cardDetails: {},
  paymentAmount: '',
  amountEditable: false,
};

export const cardAndPaymentDetailsModel = {
  state: CardAndPaymentDetailsInitialState,
  reducers: {
    setCardDetails(state, payload) {
      return { ...state, cardDetails: { ...payload } };
    },
    setPaymentAmount(state, value) {
      return { ...state, paymentAmount: value };
    },
    setAmountEditable(state, value) {
      return { ...state, amountEditable: value };
    },
    resetCardAndPaymentDetailsState(state) {
      return { ...state, ...CardAndPaymentDetailsInitialState };
    },
  },
  effects: () => ({
    async makeCallToPolicyDetailsAPI(brand, store) {
      return await callPolicyDetailsApi(brand, store);
    },
  }),
};

export default cardAndPaymentDetailsModel;
