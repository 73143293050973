const AAMI_CONSTANTS = require('./brandConstants/aamiConstants');
const GIO_CONSTANTS = require('./brandConstants/gioConstants');
const SHANNONS_CONSTANTS = require('./brandConstants/shannonsConstants');
const APIA_CONSTANTS = require('./brandConstants/apiaConstants');
const BINGLE_CONSTANTS = require('./brandConstants/bingleConstants');
const SUNCORP_CONSTANTS = require('./brandConstants/suncorpConstants');
const VERO_CONSTANTS = require('./brandConstants/veroConstants');

// Page navigation constant
const PAGE_INDEX = {
  PRN_POLICY_DETAILS_PAGE: 1,
  CARD_DETAILS_PAGE: 2,
  CONFIRM_PAYMENT_DETAILS_PAGE: 3,
  CONFIRMATION_PAGE: 4,
};

// Brand configuration constant
const BRAND_CONSTANTS = {
  aami: AAMI_CONSTANTS,
  gio: GIO_CONSTANTS,
  shannons: SHANNONS_CONSTANTS,
  apia: APIA_CONSTANTS,
  bingle: BINGLE_CONSTANTS,
  suncorp: SUNCORP_CONSTANTS,
  vero: VERO_CONSTANTS,
};

// Styleguide version
const SG_VERSION = process.env.REACT_APP_STYLEGUIDE_VERSION;

// Styleguide name constant (eg: 'aami', 'warehouse')
const SG_BRAND_NAME = process.env.REACT_APP_STYLEGUIDE_BRAND;

// Brand configuration for specific brand constant
const BRAND_CONFIG = BRAND_CONSTANTS[SG_BRAND_NAME];

const LAUNCH_DARKLY_PAYONLINE_OUTAGE_FLAG = 'payonlineoutage';
const LAUNCH_DARKLY_PAYONLINE_OUTAGE_MESSAGE = 'payonlineoutageMessage';

module.exports = {
  PAGE_INDEX,
  BRAND_CONSTANTS,
  SG_VERSION,
  SG_BRAND_NAME,
  BRAND_CONFIG,
  LAUNCH_DARKLY_PAYONLINE_OUTAGE_FLAG,
  LAUNCH_DARKLY_PAYONLINE_OUTAGE_MESSAGE,
};
