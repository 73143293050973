import React from 'react';
import {
  Paragraph,
  GridCol,
  GridRow,
} from '@suncorp/styleguide-react-components';
import * as PropType from 'prop-types';

const PaymentReceiptGridRow = ({ id, sectionName, value }) => (
  <>
    <GridRow spacing="around">
      <GridCol
        span={{
          default: 12,
          small: 12,
          large: 8,
          xlarge: 8,
        }}
      >
        <Paragraph className="sg-u-noMarginBottom">{sectionName}</Paragraph>
      </GridCol>
      <GridCol
        span={{
          default: 12,
          small: 12,
          large: 4,
          xlarge: 4,
        }}
      >
        <Paragraph id={id} className="sg-Type--bold">
          {value}
        </Paragraph>
      </GridCol>
    </GridRow>
  </>
);

PaymentReceiptGridRow.propTypes = {
  id: PropType.string,
  sectionName: PropType.string,
  value: PropType.string,
};

export default PaymentReceiptGridRow;
