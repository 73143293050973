import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Heading,
  Grid,
  GridRow,
  GridCol,
  Link,
  List,
  ListItem,
  Paragraph,
} from '@suncorp/styleguide-react-components';
import { PAGE_INDEX } from '../../../utils/constants/commonConstants';
import { BrandContext } from '../../organisms/BrandContext';

const TechErrorPage = () => {
  const [pageIndex] = useSelector((state) => [
    state.navigationProgress.currentPage,
  ]);
  const dispatch = useDispatch();
  const brandConfig = useContext(BrandContext);
  const bingleBrand = brandConfig.brandName === 'BINGLE';
  const veroBrand = brandConfig.brandName === 'VERO';

  const handleBack = (pageIndex) => {
    switch (pageIndex) {
      // When the last navigated page was the PRN/Policy details page or card capture page
      case 1:
      case 2: {
        // Clear the policyDetails state
        dispatch.policyDetails.resetPolicyDetails();
        // Set page index to PRN/Policy details page
        dispatch.navigationProgress.setPageIndex(
          PAGE_INDEX.PRN_POLICY_DETAILS_PAGE,
        );
        // Remove tech error page
        dispatch.navigationProgress.setIsTechError(false);
        break;
      }
      case 3: {
        // Clear policyDetails and cardAndPaymentDetails states
        dispatch.policyDetails.resetPolicyDetails();
        dispatch.cardAndPaymentDetails.resetCardAndPaymentDetailsState();
        // Set page index to PRN/Policy details page
        dispatch.navigationProgress.setPageIndex(
          PAGE_INDEX.PRN_POLICY_DETAILS_PAGE,
        );
        // Remove tech error page
        dispatch.navigationProgress.setIsTechError(false);
        break;
      }
    }
  };

  return (
    <section id="tech-error-section">
      <Grid>
        <GridRow>
          <GridCol>
            <Heading rank={1}>
              Oops! Looks like we couldn't process your request.
            </Heading>
          </GridCol>
        </GridRow>
        <GridRow className="sg-u-marginTop--x4">
          <GridCol>
            <Heading rank={2} className="sg-Type--heading5">
              Here's why
            </Heading>
            <List appearance="unordered">
              <ListItem>You are experiencing an application error.</ListItem>
            </List>
          </GridCol>
        </GridRow>
        <GridRow className="sg-u-marginTop--x4">
          <GridCol>
            <Heading rank={2} className="sg-Type--heading5">
              Here's what you can do next
            </Heading>
            <Paragraph>
              Please try again in a few minutes. If the issue is persistent
              contact us to complete your transaction.
            </Paragraph>
          </GridCol>
        </GridRow>
        <GridRow className="sg-u-marginTop--x2">
          <GridCol>
            {bingleBrand ? (
              <Link href={brandConfig.contactUsForm} target="_blank">
                Contact us
              </Link>
            ) : (
              <>
                <Link href={brandConfig.links.contactUsTelLink}>
                  Call us on {brandConfig.contactNumber}{' '}
                </Link>
                {veroBrand ? (
                  <>
                    or
                    <Paragraph className="sg-u-marginTop--x2">
                      Send us an email to{' '}
                      <Link
                        href={brandConfig.links.contactUsLink}
                        target="_blank"
                      >
                        {brandConfig.links.contactUsLink.slice(7)}
                      </Link>{' '}
                      with the claim no in the subject line
                    </Paragraph>
                  </>
                ) : (
                  ''
                )}
              </>
            )}
          </GridCol>
        </GridRow>
        <GridRow className="sg-u-marginTop--x4">
          <GridCol>
            <Button appearance="primary" onClick={() => handleBack(pageIndex)}>
              Try again
            </Button>
          </GridCol>
        </GridRow>
      </Grid>
    </section>
  );
};

export default TechErrorPage;
