export const sessionTimeoutInitState = {
  lastActiveTime: Date.now(),
};

const sessionTimeoutModel = {
  state: sessionTimeoutInitState,
  reducers: {
    updateLastActiveTime(state) {
      return { ...state, lastActiveTime: Date.now() };
    },
  },
};

export default sessionTimeoutModel;
