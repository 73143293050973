import React, { useContext } from 'react';
import { Grid, GridRow, GridCol } from '@suncorp/styleguide-react-components';
import CardLogo from '../../atoms/CardLogo';
import { BrandContext } from '../../organisms/BrandContext';

const CardLogoContainer = () => {
  const brandConfig = useContext(BrandContext);

  return (
    <Grid>
      <GridRow>
        <GridCol className="sg-u-flex--middle sg-u-flex--centre">
          <img
            src={require('../../../images/secure-lock-colour.svg').default}
            alt="Secure lock"
            height={40}
          />
          {brandConfig.supportedCards.map((cardLogo, index) => (
            <CardLogo
              key={cardLogo}
              logo={cardLogo}
              index={index}
              supportedCardsLength={brandConfig.supportedCards.length - 1}
            />
          ))}
        </GridCol>
      </GridRow>
    </Grid>
  );
};

export default CardLogoContainer;
