import { callPolicyPaymentApi } from '../apis/policyPaymentApis';

export const transactionDetailsInitialState = {
  receiptNumber: '',
  transactionDate: '',
};

export const transactionDetailsModel = {
  state: transactionDetailsInitialState,
  reducers: {
    setReceiptNumber(state, value) {
      return { ...state, receiptNumber: value };
    },
    setTransactionDate(state, value) {
      return { ...state, transactionDate: value };
    },
    resetTransactionDetails(state) {
      return { ...state, ...transactionDetailsInitialState };
    },
  },
  effects: () => ({
    async makeCallToPolicyPaymentAPI(brand, store) {
      return await callPolicyPaymentApi(brand, store);
    },
  }),
};

export default transactionDetailsModel;
