import React from 'react';
import PropTypes from 'prop-types';
import { Page, Main } from '@suncorp/styleguide-react-components';
import AppHeader from '../../organisms/AppHeader';
import AppFooter from '../../organisms/AppFooter';
import TestHelper from '../../organisms/TestHelper';

const PageTemplate = ({ children, appEnv }) => {
  const isBetaOrProd = appEnv === 'prod' || appEnv === 'beta';

  return (
    <Page>
      <AppHeader />
      <Main>{children}</Main>
      <TestHelper appEnv={appEnv} isBetaOrProd={isBetaOrProd} />
      <AppFooter />
    </Page>
  );
};

PageTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  appEnv: PropTypes.string.isRequired,
};

export default PageTemplate;
