import { getPersistor } from '@rematch/persist';
import { PAGE_INDEX } from '../utils/constants/commonConstants';

// Middleware to navigate to session timeout page if the user has not navigated pages in 20 mins.
export const sessionTimeoutMiddleware = () => (store) => (next) => (action) => {
  const state = store.getState();
  // When setPageIndex is called.
  if (shouldCheckActiveStateDuringTransition(action, store)) {
    // Get the current epoch time.
    const currentActiveTime = Date.now();
    const { lastActiveTime } = state.sessionTimeout;
    const pageIndex = state.navigationProgress.currentPage;
    // If the user has not changed pages in 20 mins OR
    // the session has already timed out OR
    // the current page is the PRN/policy details page or confirmation page.
    if (
      isActiveCustomer(lastActiveTime, currentActiveTime) ||
      state.navigationProgress.isSessionTimeout ||
      pageIndex === PAGE_INDEX.PRN_POLICY_DETAILS_PAGE ||
      pageIndex === PAGE_INDEX.CONFIRMATION_PAGE
    ) {
      // Set the last active time to the current time.
      store.dispatch({
        type: 'SET_LAST_ACTIVE_TIME',
        payload: currentActiveTime,
      });
      // Return and complete next action.
      return next(action);
    }
    // Reachable only when:
    // - isActiveCustomer() returns false (the user hasn't navigated between pages for 20 mins) AND
    // - The current page is not session timeout (we don't need to timeout the timeout page) AND
    // - The current page is not PRN/policy details (timeout not required for this page) AND
    // - The current page is not confirmation (the user flow has ended and the user cannot do any other actions).
    const persistor = getPersistor();
    setTimeout(() => {
      // Force the writing of all pending states so that the reset can happen smoothly.
      persistor.flush();
      // Reset the redux states individually.
      // It is not possible to dispatch the built-in 'RESET' action as it removes the persist object.
      store.dispatch({
        type: 'navigationProgress/resetNavigationProgress',
      });
      store.dispatch({
        type: 'policyDetails/resetPolicyDetails',
      });
      store.dispatch({
        type: 'cardAndPaymentDetails/resetCardAndPaymentDetailsState',
      });
      store.dispatch({
        type: 'transactionDetails/resetTransactionDetails',
      });
      store.dispatch({
        type: 'helpers/resetHelpers',
      });
      // Purge the storage data.
      persistor.purge();
      // Navigate to the session timeout page.
      store.dispatch({
        type: 'navigationProgress/setIsSessionTimeout',
        payload: true,
      });
    }, 0);

    return null;
  }
  // We don't need to check if the action is not setPageIndex, so just return the next action.
  return next(action);
};

// Check if the difference between the time page index was last set and the current time is less than configured timeout.
export const isActiveCustomer = (lastActiveTime, currentActiveTime) =>
  currentActiveTime - lastActiveTime < window.envConfig.sessionTimeout;

// Trigger middleware only when page index is changed.
const shouldCheckActiveStateDuringTransition = (action, store) =>
  action.type === 'navigationProgress/setPageIndex';
