import React, { useContext, useEffect, useRef } from 'react';
import { Button, Heading } from '@suncorp/styleguide-react-components';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { PAGE_INDEX } from '../../../utils/constants/commonConstants';
import CardCaptureComponent from '../../organisms/CardCaptureComponent';
import { MESSAGE_TYPE } from '../../organisms/CardCaptureComponent/utils/constants';
import { postMessageToChild } from '../../organisms/CardCaptureComponent/utils/helpers';
import BusinessErrorCard from '../../molecules/BusinessErrorCard';
import { BrandContext } from '../../organisms/BrandContext';
import {
  updateAnalyticsOnBusinessOrTechnicalError,
  updateAnalyticsOnPageRender,
} from '../../../utils/analytics/dataLayerUtils';

const CardDetailsPage = ({
  isPaymentError,
  setIsPaymentError,
  paymentErrorMessage,
  setPaymentErrorMessage,
  paymentBusinessErrorMessage,
}) => {
  const frameElem = useRef(null);
  const brandConfig = useContext(BrandContext);

  const dispatch = useDispatch();

  const [
    prnNumber,
    policyNumber,
    paymentAmount,
    cardType,
    inRenewal,
    productDetails,
  ] = useSelector((state) => [
    state.policyDetails.prnNumber,
    state.policyDetails.policyNumber,
    state.cardAndPaymentDetails.paymentAmount,
    state.cardAndPaymentDetails.cardDetails.cardType,
    state.policyDetails.inRenewal,
    state.policyDetails.productDetails,
  ]);

  // Update dataLayer and publish pageview
  useEffect(
    () => {
      // Update analytics only once DOM has fully loaded
      const onPageLoad = () => {
        updateAnalyticsOnPageRender(
          PAGE_INDEX.CARD_DETAILS_PAGE,
          prnNumber,
          policyNumber,
          paymentAmount,
          cardType,
          inRenewal,
          productDetails,
        );
      };

      // Check if the page has already loaded
      if (document.readyState === 'complete') {
        onPageLoad();
      } else {
        window.addEventListener('load', onPageLoad, false);
        // Remove the event listener when component unmounts
        return () => window.removeEventListener('load', onPageLoad);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // Update dataLayer when error occurs and publish pageview
  useEffect(() => {
    // Update analytics when error updates
    if (isPaymentError) {
      updateAnalyticsOnBusinessOrTechnicalError(
        PAGE_INDEX.CARD_DETAILS_PAGE,
        paymentBusinessErrorMessage,
        productDetails,
        isPaymentError,
        false,
      );
    }
  }, [isPaymentError, paymentBusinessErrorMessage, productDetails]);

  return (
    <section id="card-details-section" className="sg-u-width10of10">
      {/* Show title and error only if iframe has loaded */}
      {frameElem.current && (
        <>
          <Heading rank={1} role="region" aria-live="polite">
            Card details
          </Heading>
          {isPaymentError && (
            <BusinessErrorCard
              errorTitle={"We couldn't process your payment"}
              errorMessage={paymentErrorMessage}
              isPaymentError={true}
              brandConfig={brandConfig}
            />
          )}
        </>
      )}
      <CardCaptureComponent ref={frameElem} />

      {/* Show navigation buttons only when iframe has loaded*/}
      {frameElem.current && (
        <div className="sg-Form-pager sg-Form-pager-collapseMedium sg-u-paddingTop">
          <Button
            appearance="primary"
            className="sg-Btn--callToAction"
            onClick={() => {
              // Show the loading spinner.
              dispatch.helpers.setLoading({
                isLoading: true,
                loaderComponent: 'defaultLoadingIndicator',
              });
              // Submit form to CCC.
              const mType = MESSAGE_TYPE.FORM_SUBMIT;
              postMessageToChild(frameElem.current, mType, null);
              // Clear errors.
              setIsPaymentError(false);
              setPaymentErrorMessage('');
            }}
          >
            Next
          </Button>
          <Button
            appearance="tertiary"
            onClick={() => {
              // Go to previous page.
              dispatch.navigationProgress.setPageIndex(
                PAGE_INDEX.PRN_POLICY_DETAILS_PAGE,
              );
              // Clear errors.
              setIsPaymentError(false);
              setPaymentErrorMessage('');
            }}
          >
            Back
          </Button>
        </div>
      )}
    </section>
  );
};

CardDetailsPage.propTypes = {
  isPaymentError: PropTypes.bool.isRequired,
  setIsPaymentError: PropTypes.func.isRequired,
  paymentErrorMessage: PropTypes.string.isRequired,
  setPaymentErrorMessage: PropTypes.func.isRequired,
  paymentBusinessErrorMessage: PropTypes.string.isRequired,
};

export default CardDetailsPage;
