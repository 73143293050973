import createApiInstance from '../utils/apis/createApiInstance';
import { makePolicyDetailsAPIRequest } from './mappers/policyDetailsApiMappers';

export const callPolicyDetailsApi = async (brand, state) => {
  const policyDetailsUrl = `${window.envConfig.digitalPaymentsApiBaseUrl}/policyDetails`;
  const requestBody = makePolicyDetailsAPIRequest(brand, state);
  const response = await createApiInstance({
    timeout: 20000,
  }).post(policyDetailsUrl, requestBody);
  return response.data?.data?.attributes;
};
