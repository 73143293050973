import React from 'react';
import {
  Paragraph,
  GridCol,
  GridRow,
} from '@suncorp/styleguide-react-components';
import * as PropType from 'prop-types';

const CommonGridRow = ({ sectionName, value }) => (
  <>
    <GridRow spacing="around" className="sg-u-marginBottom">
      <GridCol
        span={{
          default: 12,
          large: 8,
          small: 12,
          xlarge: 8,
        }}
        contentAlign="middleLeft"
      >
        <Paragraph className="sg-Type--small sg-u-noMarginBottom">
          {sectionName}
        </Paragraph>
      </GridCol>
      <GridCol
        span={{
          default: 12,
          large: 4,
          small: 12,
          xlarge: 4,
        }}
        contentAlign="middleRight"
      >
        <Paragraph className="sg-Type--bold sg-u-mediumAndSmallHidden sg-u-noMarginBottom">
          {value}
        </Paragraph>
        <Paragraph className="sg-Type--bold sg-u-floatLeft sg-u-width10of10 sg-u-largeHidden">
          {value}
        </Paragraph>
      </GridCol>
    </GridRow>
  </>
);

CommonGridRow.propTypes = {
  sectionName: PropType.string,
  value: PropType.string,
};

export default CommonGridRow;
