import { v4 as uuidV4 } from 'uuid';

export const makePolicyPaymentAPIRequest = (brand, state) => ({
  data: {
    type: 'policyPaymentRequest',
    id: uuidV4(),
    attributes: {
      policyPaymentRequest: {
        digitalPaymentId: state.helpers.digitalPaymentId,
        correlationId: state.helpers.correlationId,
        paymentReferenceNumber: state.policyDetails.prnNumber,
        policyNumber: state.policyDetails.policyNumber,
        emailAddress: state.policyDetails.email,
        typeOfPRN: state.policyDetails.typeOfPrn,
        brand: brand,
        channel: 'WEB',
        cardDetails: {
          nameOnCard: state.cardAndPaymentDetails.cardDetails.nameOnCard,
          cardType: state.cardAndPaymentDetails.cardDetails.cardType,
          maskedCardNumber: state.cardAndPaymentDetails.cardDetails.cardNumber,
          expiryDateYear: state.cardAndPaymentDetails.cardDetails.expiryYear,
          expiryDateMonth: state.cardAndPaymentDetails.cardDetails.expiryMonth,
          maskedCVVNumber: state.cardAndPaymentDetails.cardDetails.securityCode,
          amount: state.cardAndPaymentDetails.paymentAmount,
          cardTokenNumber: state.cardAndPaymentDetails.cardDetails.cardToken,
        },
      },
    },
  },
});
