import React from 'react';
import PropTypes from 'prop-types';
import { SG_VERSION } from '../../../utils/constants/commonConstants';
import classnames from 'classnames';

export const getCardLogoAltText = (logo) => {
  switch (logo) {
    case 'visa':
      return 'Visa';
    case 'mastercard':
      return 'Mastercard';
    case 'americanexpress':
      return 'American Express';
    case 'eftpos':
      return 'Eftpos';
  }
};

const CardLogo = ({ logo, index, supportedCardsLength }) => (
  <img
    id={`${logo}-card-logo`}
    src={`https://styleguide-assets.suncorp.com.au/${SG_VERSION}/default/img/logos/${logo}_badge.svg`}
    className={classnames({
      'sg-u-marginLeft sg-u-marginRight--x2': index === 0,
      'sg-u-marginRight--x2': index !== 0 && index !== supportedCardsLength,
    })}
    alt={getCardLogoAltText(logo)}
    height={40}
  />
);

CardLogo.propTypes = {
  logo: PropTypes.string,
  index: PropTypes.number,
  supportedCardsLength: PropTypes.number,
};

export default CardLogo;
