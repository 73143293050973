import axios from 'axios';
import { v4 as uuidV4 } from 'uuid';
import { commonConfig } from '../../config/common';

export const getHeaders = () => ({
  // We do not send correlation ID - since it gets overridden by Axway for its own use
  'Content-Type': 'application/vnd.api+json',
  'X-Client-Version': '1.0',
  'X-Client-ID': commonConfig.appId,
  'X-Request-ID': uuidV4(),
  'Cache-Control': 'no-cache',
});

const createApiInstance = ({ baseURL, timeout }) => {
  const headers = getHeaders();
  return axios.create({
    baseURL,
    timeout,
    headers,
  });
};

export default createApiInstance;
