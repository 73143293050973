import React from 'react';
import ReactDOM from 'react-dom';
import AppPage from './components/pages/AppPage';
import { Provider } from 'react-redux';
import configureStore from './utils/configureStore';
import bootstrapEnsighten from './scripts/ensighten';
import {
  asyncWithLDProvider,
  basicLogger,
} from 'launchdarkly-react-client-sdk';

const envConfig = window.envConfig;
const store = configureStore(envConfig.sessionStorageKey);

bootstrapEnsighten(envConfig.ensighten);

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: envConfig.launchDarklyClientSideID,
    options: {
      logger: basicLogger({ level: 'none' }),
    },
  });

  ReactDOM.render(
    <Provider store={store}>
      <LDProvider>
        <AppPage config={envConfig} />
      </LDProvider>
    </Provider>,
    document.getElementById('react-container'),
  );
})();
