import React, { forwardRef, useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addMessageListener,
  getContext,
  getSanitizedData,
  postMessageToChild,
  setElemAttributes,
} from './utils/helpers';
import { MESSAGE_TYPE, TECH_ERROR_TYPE } from './utils/constants';
import { BrandContext } from '../BrandContext';
import { PAGE_INDEX } from '../../../utils/constants/commonConstants';
import { updateAnalyticsOnBusinessOrTechnicalError } from '../../../utils/analytics/dataLayerUtils';

const CardCaptureComponent = forwardRef((props, frameElem) => {
  const brandConfig = useContext(BrandContext);
  const dispatch = useDispatch();
  const [
    { paymentAmount, amountEditable },
    productDetails,
  ] = useSelector((state) => [
    state.cardAndPaymentDetails,
    state.policyDetails.productDetails,
  ]);

  const frameWrapper = useRef(null);
  const frameHeight = useRef(0);

  const handleReceivedMessage = async (event) => {
    // Sanitize the data in the event before using it.
    const { type, payload } = getSanitizedData(event);
    switch (type) {
      // When ready state is received.
      case MESSAGE_TYPE.FRAME_READY: {
        // Post context options to child frame.
        const mType = MESSAGE_TYPE.FRAME_CONTEXT;
        const context = getContext(paymentAmount, amountEditable, brandConfig);
        postMessageToChild(frameElem.current, mType, context);
        // Save correlationId into redux for use in payment processing
        dispatch.helpers.setCorrelationId(context.correlationId);
        break;
      }

      // When height needs adjustment.
      case MESSAGE_TYPE.FRAME_HEIGHT: {
        // Set received height as min-height of frame wrapper.
        const { height = 0 } = payload || {};
        // Add to the frame height so that the tooltip always displays fully
        frameWrapper.current.style.minHeight = `${height + 120}px`;
        frameHeight.current = height + 120;
        frameWrapper.current.style.width = '501px';
        frameWrapper.current.className = 'sg-u-width10of10--large';
        // Frame is still loading if height is 0. Show spinner until height has a value greater than 1.
        if (frameHeight.current > 1) {
          dispatch.helpers.setLoading({
            isLoading: false,
            loaderComponent: '',
          });
        }
        break;
      }

      // When payment amount field is changed.
      case MESSAGE_TYPE.FIELD_AMT_CHANGED: {
        dispatch.cardAndPaymentDetails.setPaymentAmount(payload.value);
        break;
      }

      // When card is tokenised successfully.
      case MESSAGE_TYPE.TOKEN_SUCCESS: {
        // Save card details into redux.
        dispatch.cardAndPaymentDetails.setCardDetails({
          cardToken: payload.cardToken,
          nameOnCard: payload.creditCard.nameOnCard,
          cardNumber: payload.creditCard.cardNumber,
          cardType: payload.creditCard.cardType,
          expiryMonth: payload.creditCard.expiryMonth,
          expiryYear: payload.creditCard.expiryYear,
          securityCode: payload.creditCard.securityCode,
        });
        // Go to next page.
        dispatch.navigationProgress.setPageIndex(
          PAGE_INDEX.CONFIRM_PAYMENT_DETAILS_PAGE,
        );
        // Hide the loading spinner.
        dispatch.helpers.setLoading({
          isLoading: false,
          loaderComponent: '',
        });
        break;
      }

      // When CCC form returns an error.
      case MESSAGE_TYPE.FORM_ERROR: {
        // Hide the loading spinner
        dispatch.helpers.setLoading({
          isLoading: false,
          loaderComponent: '',
        });
        break;
      }

      // When card is not tokenised successfully.
      case MESSAGE_TYPE.TOKEN_ERROR: {
        // updating the dataLayer with appropriate error message.
        updateAnalyticsOnBusinessOrTechnicalError(
          PAGE_INDEX.CARD_DETAILS_PAGE,
          TECH_ERROR_TYPE.TOKEN_ERR,
          productDetails,
          false,
          true,
        );
        // Go to tech error and hide the loading spinner.
        dispatch.navigationProgress.setIsTechError(true);
        dispatch.helpers.setLoading({
          isLoading: false,
          loaderComponent: '',
        });
        break;
      }
    }
  };

  // If the frame is still loading after 10 seconds, navigate to tech error.
  const configureTimeout = () => {
    setTimeout(() => {
      if (frameHeight.current < 1) {
        // updating the dataLayer with appropriate error message.
        updateAnalyticsOnBusinessOrTechnicalError(
          PAGE_INDEX.CARD_DETAILS_PAGE,
          TECH_ERROR_TYPE.TIMEOUT_ERR,
          productDetails,
          false,
          true,
        );
        dispatch.navigationProgress.setIsTechError(true);
        dispatch.helpers.setLoading({
          isLoading: false,
          loaderComponent: '',
        });
      }
    }, 10000);
  };

  const getVersionDDMM = () => {
    const today = new Date();
    const dd = today.getDate();
    const mm = today.getMonth() + 1;
    return `?ver=${dd}${mm}`;
  };

  useEffect(() => {
    configureTimeout();
    addMessageListener(handleReceivedMessage);
    setElemAttributes(frameElem.current, {
      src: window.envConfig.cardCaptureComponentUrl + getVersionDDMM(),
      sandbox:
        'allow-forms allow-top-navigation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox',
      scrolling: 'no',
    });

    return () => {
      window.removeEventListener('message', handleReceivedMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={frameWrapper} style={{ height: `${frameHeight.current}px` }}>
      <iframe
        id="ccc-iframe"
        title="ccc-iframe"
        className="sg-u-noBorder sg-u-width10of10"
        style={{ height: '100%' }}
        src="about:blank"
        ref={frameElem}
      />
    </div>
  );
});

export default CardCaptureComponent;
