import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import PageTemplate from '../../templates/PageTemplate';
import PRNPolicyDetailsPage from '../PRNPolicyDetailsPage';
import PageContent from '../../organisms/PageContent';
import CardDetailsPage from '../CardDetailsPage';
import ConfirmPaymentDetailsPage from '../ConfirmPaymentDetailsPage';
import ConfirmationPage from '../ConfirmationPage';
import TechErrorPage from '../TechErrorPage';
import {
  BRAND_CONFIG,
  LAUNCH_DARKLY_PAYONLINE_OUTAGE_FLAG,
  PAGE_INDEX,
} from '../../../utils/constants/commonConstants';
import { BrandContext } from '../../organisms/BrandContext';
import LoadingSpinner from '../../molecules/LoadingSpinner';
import SessionTimeoutPage from '../SessionTimeoutPage';
import MaintenancePage from '../MaintenancePage';
import { useLaunchDarklyFlag } from '../../../utils/launchDarkly';

function App({ config }) {
  const [
    pageIndex,
    isTechError,
    isSessionTimeout,
    isPaymentTimeout,
  ] = useSelector((state) => [
    state.navigationProgress.currentPage,
    state.navigationProgress.isTechError,
    state.navigationProgress.isSessionTimeout,
    state.navigationProgress.isPaymentTimeout,
  ]);
  const { isLoading, loaderComponent } = useSelector(
    (state) => state.helpers.loadingHelper,
  );

  const [isPaymentError, setIsPaymentError] = useState(false);
  const [paymentErrorMessage, setPaymentErrorMessage] = useState('');
  const [
    paymentBusinessErrorMessage,
    setPaymentBusinessErrorMessage,
  ] = useState('');

  const isPayOnlineOutage = useLaunchDarklyFlag()[
    LAUNCH_DARKLY_PAYONLINE_OUTAGE_FLAG
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    if (isPayOnlineOutage) {
      dispatch({
        type: 'navigationProgress/resetNavigationProgress',
      });
      dispatch({
        type: 'policyDetails/resetPolicyDetails',
      });
      dispatch({
        type: 'cardAndPaymentDetails/resetCardAndPaymentDetailsState',
      });
    }
  }, [dispatch, isPayOnlineOutage]);

  const renderContentPage = (pageIndex) => {
    switch (pageIndex) {
      case PAGE_INDEX.PRN_POLICY_DETAILS_PAGE:
        return <PRNPolicyDetailsPage />;
      case PAGE_INDEX.CARD_DETAILS_PAGE:
        return (
          <CardDetailsPage
            isPaymentError={isPaymentError}
            setIsPaymentError={setIsPaymentError}
            paymentErrorMessage={paymentErrorMessage}
            setPaymentErrorMessage={setPaymentErrorMessage}
            paymentBusinessErrorMessage={paymentBusinessErrorMessage}
          />
        );
      case PAGE_INDEX.CONFIRM_PAYMENT_DETAILS_PAGE:
        return (
          <ConfirmPaymentDetailsPage
            setIsPaymentError={setIsPaymentError}
            setPaymentErrorMessage={setPaymentErrorMessage}
            setPaymentBusinessErrorMessage={setPaymentBusinessErrorMessage}
          />
        );
      case PAGE_INDEX.CONFIRMATION_PAGE:
        return <ConfirmationPage />;
    }
  };

  const renderErrorPage = (isSessionTimeout, isPayOnlineOutage) => {
    if (isSessionTimeout) {
      return <SessionTimeoutPage />;
    } else if (isPayOnlineOutage) {
      return <MaintenancePage />;
    } else {
      return <TechErrorPage />;
    }
  };
  return (
    <BrandContext.Provider value={BRAND_CONFIG}>
      <PageTemplate appEnv={config.appEnv}>
        <PageContent
          gridColLargeSpan={
            isTechError ||
            isSessionTimeout ||
            isPayOnlineOutage ||
            isPaymentTimeout
              ? 10
              : 6
          }
          pageIndex={
            isTechError ||
            isSessionTimeout ||
            isPayOnlineOutage ||
            isPaymentTimeout
              ? 0
              : pageIndex
          }
          setIsPaymentError={setIsPaymentError}
          setPaymentErrorMessage={setPaymentErrorMessage}
          setPaymentBusinessErrorMessage={setPaymentBusinessErrorMessage}
        >
          {isLoading && loaderComponent && <LoadingSpinner />}
          {isTechError ||
          isSessionTimeout ||
          isPayOnlineOutage ||
          isPaymentTimeout
            ? renderErrorPage(isSessionTimeout, isPayOnlineOutage)
            : renderContentPage(pageIndex)}
        </PageContent>
      </PageTemplate>
    </BrandContext.Provider>
  );
}

App.propTypes = {
  config: PropTypes.object.isRequired,
};

export default App;
