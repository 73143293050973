import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  Heading,
  Grid,
  GridRow,
  GridCol,
  Paragraph,
} from '@suncorp/styleguide-react-components';
import { PAGE_INDEX } from '../../../utils/constants/commonConstants';

const SessionTimeoutPage = () => {
  const dispatch = useDispatch();

  const handleBack = () => {
    // Navigate to first page.
    dispatch.navigationProgress.setPageIndex(
      PAGE_INDEX.PRN_POLICY_DETAILS_PAGE,
    );
    // Hide the session timeout page.
    dispatch.navigationProgress.setIsSessionTimeout(false);
  };

  return (
    <section id="session-timeout-section">
      <Grid>
        <GridRow>
          <GridCol>
            <Heading rank={1}>Looks like you've been away too long</Heading>
          </GridCol>
        </GridRow>
        <GridRow className="sg-u-marginTop--x4 sg-u-paddingLeft sg-u-paddingRight">
          <Paragraph>
            For security reasons, your session has timed out.
          </Paragraph>
          <Paragraph>
            If you've made your payment, check your email or log in to track it.
            Unfortunately, if your payment wasn't submitted, you'll need to
            start again.
          </Paragraph>
        </GridRow>
        <GridRow className="sg-u-marginTop--x4">
          <GridCol>
            <Button appearance="primary" onClick={handleBack}>
              Start again
            </Button>
          </GridCol>
        </GridRow>
      </Grid>
    </section>
  );
};

export default SessionTimeoutPage;
