import React, { useContext } from 'react';
import {
  Header,
  Paragraph,
  Icon,
  Link,
} from '@suncorp/styleguide-react-components';
import { BrandContext } from '../../BrandContext';

const DefaultHeader = () => {
  const brandConfig = useContext(BrandContext);
  return (
    <Header className="sg-u-paddingBottom">
      <div className="sg-Header-column">
        <div className="sg-Header-logo">{brandConfig.brandName} </div>
      </div>
      <div className="sg-Header-column sg-u-width10of10 sg-u-textRight">
        <Paragraph className="sg-Header-title sg-Type--heading6">
          <Icon
            name="padlock"
            className="Icon-lock--default Icon--medium sg-u-imageReplacement"
          >
            setting
          </Icon>
          Secure Payment
        </Paragraph>
        <Link
          href={brandConfig.links.contactUsLink}
          target="_blank"
          className="sg-Type--linkInverse"
        >
          Need help? Contact us
        </Link>
      </div>
    </Header>
  );
};

export default DefaultHeader;
