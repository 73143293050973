import { v4 as uuidV4 } from 'uuid';

export const makePolicyDetailsAPIRequest = (brand, state) => ({
  data: {
    type: 'policyDetailsRequest',
    id: uuidV4(),
    attributes: {
      policyDetailsRequest: {
        paymentReferenceNumber: state.policyDetails.prnNumber,
        emailAddress: state.policyDetails.email,
        brand: brand,
        channel: 'WEB',
      },
    },
  },
});
