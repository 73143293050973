import React from 'react';
import {
  FormQuestion,
  Paragraph,
  TextInput,
} from '@suncorp/styleguide-react-components';
import * as PropType from 'prop-types';
import { useFormikContext } from 'formik';

const EmailInput = ({ bindFieldToValidation, defaultValue }) => {
  const { setFieldValue } = useFormikContext();
  const usernameAndDomainPattern =
    '[_A-Za-z0-9-]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9-]+)';
  const domainExtensionPattern = '*(\\.[_A-Za-z0-9-]+)';
  const emailRegex = new RegExp(
    `^${usernameAndDomainPattern}${domainExtensionPattern}$`,
  );

  return (
    <>
      <FormQuestion
        id="email-input-question"
        data-testid="email-input-question"
        name="emailAddress"
        label="Email address"
        className="sg-u-noMarginBottom sg-u-marginTop--x2"
        bindFieldToValidation={bindFieldToValidation}
        validations={[
          {
            rule: (value) => !!value,
            message: 'Please enter an email address',
          },
          {
            rule: (value) => emailRegex.test(value),
            message: 'Please enter a valid email address',
          },
        ]}
      >
        <TextInput
          id="email-input"
          data-testid="email-input"
          type="email"
          className="sg-u-width10of10"
          autoComplete="email"
          defaultValue={defaultValue}
          onChange={(e) => setFieldValue('emailAddress', e.target.value)}
        />
      </FormQuestion>
      <Paragraph className="sg-Form-helpText">
        We will send your payment receipt to this email address
      </Paragraph>
    </>
  );
};

EmailInput.propTypes = {
  bindFieldToValidation: PropType.func,
  defaultValue: PropType.string,
};

export default EmailInput;
