function bootstrapEnsighten({ dataLayer, bootstrapUrl }) {
  // Set the initial dataLayer from config
  window.dataLayer = window.dataLayer || dataLayer;

  // Need to separate this line out if there are multiple pages needed to be tracked
  window.dataLayer.pageName = 'in:ami:testPageName';

  const bootstrapScriptElement = document.createElement('script');
  const firstScriptTag = document.getElementsByTagName('script')[0];
  // Async will help with initial page load performance
  bootstrapScriptElement.async = 1;
  bootstrapScriptElement.src = bootstrapUrl;
  firstScriptTag.parentNode.insertBefore(
    bootstrapScriptElement,
    firstScriptTag,
  );
}

export default bootstrapEnsighten;
