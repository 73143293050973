import React, { useContext } from 'react';
import { BrandContext } from '../BrandContext';
import DefaultHeader from './components/DefaultHeader';
import VeroHeader from './components/VeroHeader';

const AppHeader = () => {
  const brandConfig = useContext(BrandContext);
  switch (brandConfig.brandName) {
    case 'VERO':
      return <VeroHeader />;
    default:
      return <DefaultHeader />;
  }
};

export default AppHeader;
