import React, { useContext } from 'react';
import {
  Footer,
  Link,
  List,
  ListItem,
  Paragraph,
} from '@suncorp/styleguide-react-components';
import { BrandContext } from '../../BrandContext';

const ApiaFooter = () => {
  const brandConfig = useContext(BrandContext);
  return (
    <Footer>
      <List appearance="unstyled" className="sg-List-horizontal">
        <ListItem>
          <Link
            href={brandConfig.links.privacyStatementLink}
            target="_blank"
            className="sg-Type--linkInverse"
          >
            Online Terms &amp; Privacy Statement
          </Link>
        </ListItem>
      </List>
      <Paragraph className="sg-Type--small sg-Type--muted">
        {
          '© Australian Pensioners Insurance Agency Pty Ltd ABN 14 099 650 996 (Apia). Apia AR 239591 is an authorised representative of AAI Limited ABN 48 005 297 807 AFSL 230859 (AAI), the issuer of Home, Landlord, Car, Caravan , Motorhome, Boat, Travel & Compulsory Third Party Personal Injury (CTP) Insurance. Before purchase, consider the '
        }
        <Link
          href={brandConfig.links.policyDocumentsLink}
          target="_blank"
          className="sg-Type--linkInverse"
        >
          PDS
        </Link>
        {
          ' on this website. This advice has been prepared without taking into account your particular objectives, financial situations or needs, so you should consider whether it is appropriate for you before acting on it'
        }
      </Paragraph>
    </Footer>
  );
};

export default ApiaFooter;
