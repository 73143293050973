import { isPolicyNumberFormat } from '../validators';

// Get business error message to display to user
export const getBusinessErrors = (brandConfig, prnNumber) => ({
  POLICY_OR_PRN_NOT_FOUND: `The ${
    isPolicyNumberFormat(prnNumber)
      ? brandConfig.brandName !== 'VERO'
        ? 'policy number'
        : 'PRN'
      : 'PRN'
  } you entered is not valid. Please check your details again. If you still have trouble,
  ${
    brandConfig.brandName !== 'BINGLE'
      ? `${
          brandConfig.brandName !== 'VERO'
            ? `give us a call on ${brandConfig.contactNumber} and one of our
          Sales and Service Consultants will assist you.`
            : ''
        }`
      : 'please '
  }
  `,
  NO_OUTSTANDING_AMOUNT: `There seems to be no outstanding amount owing for the ${
    isPolicyNumberFormat(prnNumber) ? 'policy number' : 'PRN'
  } entered.
  ${
    brandConfig.brandName !== 'BINGLE'
      ? `Please give us a call on ${brandConfig.contactNumber} and one of our Sales and Service Consultants will assist you.`
      : 'Please '
  }`,
  INVALID_EXPIRED_PAYMENT_PROCESS_CARD:
    'The card number you have entered is not valid. Please enter a valid number as shown on your credit card.',
  DECLINED_INSUFFICIENT_AMOUNT_CARD:
    'Your credit card payment has not been approved. Please use another credit card.',
  PAYMENT_GATEWAY_ERROR:
    'Due to technical difficulties, we are unable to process your credit card payment at this time.',
});

// Get error title from API response for policy validation errors
export const VALIDATION_ERROR_TITLE = {
  POLICY_PRN_NOT_FOUND: 'POLICY_PRN_NOT_FOUND',
  POLICY_PRN_NOT_VALID: 'POLICY_PRN_NOT_VALID',
  NO_OUTSTANDING_AMOUNT_PRN: 'NO_OUTSTANDING_AMOUNT_PRN',
  NO_OUTSTANDING_AMOUNT_POLICY_NUMBER: 'NO_OUTSTANDING_AMOUNT_POLICY_NUMBER',
  POLICY_CANCELLED: 'POLICY_CANCELLED',
  POLICY_EXPIRED: 'POLICY_EXPIRED',
};

// Get error title from API response for payment errors
export const PAYMENT_ERROR_TITLE = {
  INVALID_CARD_DETAILS: 'INVALID_CARD_DETAILS',
  DECLINED_CARD: 'DECLINED_CARD',
  EXPIRED_CARD: 'EXPIRED_CARD',
  INSUFFICIENT_AMOUNT_CARD: 'PAYMENT_NOT_APPROVED',
  PAYMENT_PROCESS_EXCEPTION: 'PAYMENT_PROCESS_EXCEPTION',
  PAYMENT_GATEWAY_ERROR: 'PAYMENT_GATEWAY_ERROR',
};
