import React from 'react';
import PropTypes from 'prop-types';
import { SG_VERSION } from '../../../utils/constants/commonConstants';

export const getCardImage = (type) => {
  switch (type) {
    case 'VISA':
      return 'visa';
    case 'MASTERCARD':
      return 'mastercard_badge';
    case 'AMEX':
      return 'americanexpress';
    case 'eftpos':
      return 'eftpos';
  }
};

const CardTypeImage = ({ type, className }) => (
  <img
    id={`${type}-card-type-image`}
    src={`https://styleguide-assets.suncorp.com.au/${SG_VERSION}/default/img/logos/${getCardImage(
      type,
    )}.svg`}
    className={className}
    alt={getCardImage(type)}
    width={42}
  />
);

CardTypeImage.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
};

export default CardTypeImage;
