import React from 'react';
import { ListItem } from '@suncorp/styleguide-react-components';
import * as PropType from 'prop-types';

const CompleteProgressStep = ({ children }) => (
  <ListItem
    className="sg-Progress-step sgjs-prev sg-Progress-step--prev sg-u-noMargin"
    data-position="completed"
  >
    <span className="sg-u-visuallyhidden">Completed step:</span>
    {children}
  </ListItem>
);

CompleteProgressStep.propTypes = {
  children: PropType.node.isRequired,
};

export default CompleteProgressStep;
