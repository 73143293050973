export const helpersModelInitialState = {
  loadingHelper: {
    isLoading: false,
    loaderComponent: '',
  },
  digitalPaymentId: '',
  correlationId: '',
};

export const helpersModel = {
  state: helpersModelInitialState,
  reducers: {
    setLoading(state, payload) {
      return { ...state, loadingHelper: { ...payload } };
    },
    setDigitalPaymentId(state, value) {
      return { ...state, digitalPaymentId: value };
    },
    setCorrelationId(state, value) {
      return { ...state, correlationId: value };
    },
    resetHelpers(state) {
      return { ...state, ...helpersModelInitialState };
    },
  },
  effects: (dispatch) => ({
    /**
     * This function is responsible to call the callback function passed through it while setting value of `isLoading` in redux store accordingly.
     * The `isLoading` state can be used to show a spinner or to map progress of an async call. By default, it will NOT show any loading spinner
     * unless we pass a string value to the `loaderComponent` arg.
     *
     * If the callee component wants to show another spinner on completion of the async call, it can pass a function `showSuccessLoader`. It takes
     * one parameter - response from the API/async call. It is expected to return either of:
     * - `false` if it does not want to show a success spinner.
     * - The name of the loading spinner to show. Consult `LoadingSpinner` component for the list.
     *
     * If the callee component does not want to hide the spinner on completion of the async call, it can pass a value for `hideSpinnerAfterSuccess.
     * This is `true` by default.
     */
    async callWithLoader({
      asyncCallbackFunction,
      loaderComponent = null,
      showSuccessLoader = () => {},
      hideSpinnerAfterSuccess = true,
    }) {
      // set `isLoading` true and show the loading spinner
      dispatch.helpers.setLoading({
        isLoading: true,
        loaderComponent,
      });

      let response;
      try {
        // make async call
        response = await asyncCallbackFunction();

        // show success loader if a response is returned and returns a truthy value when passed through `showSuccessLoader`
        const successSpinner = showSuccessLoader(response);
        if (successSpinner) {
          dispatch.helpers.setLoading({
            isLoading: true,
            loaderComponent: successSpinner,
          });
          // show the success spinner for 1 second
          await new Promise((r) => setTimeout(r, 1000));
        }
      } finally {
        // if the callee component wants to hide the spinner on success
        if (hideSpinnerAfterSuccess) {
          // set `isLoading` false and remove the loading spinner
          dispatch.helpers.setLoading({
            isLoading: false,
            loaderComponent: null,
          });
        }
      }
      return response;
    },
  }),
};
