const SHANNONS_CONSTANTS = {
  // Readable brand name used in the display
  brandName: 'SHANNONS',
  // Readable brand name used in the PRNPolicyDisplay Page
  brandNameCaseSense: 'Shannons',
  // Brand name used in API calls
  apiBrandName: 'SHANNONS',
  supportedCards: ['visa', 'mastercard', 'eftpos'],
  links: {
    policyFinderLink:
      'https://online.shannons.com.au/policyfinder/shannons/#/websites/customer',
    contactUsLink: 'https://www.shannons.com.au/contact-us.html',
    contactUsTelLink: 'tel:134646',
    privacyStatementLink: 'https://www.shannons.com.au/privacy.html',
  },
  contactNumber: '13 46 46',
  copyrightStatement:
    '© Copyright 2013 Shannons Pty Limited ABN 91 099 692 636 is an agent and authorised representative of GIO',
};

module.exports = SHANNONS_CONSTANTS;
