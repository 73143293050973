const BINGLE_CONSTANTS = {
  // Readable brand name used in the display
  brandName: 'BINGLE',
  // Readable brand name used in the PRNPolicyDisplay Page
  brandNameCaseSense: 'Bingle',
  // Brand name used in API calls
  apiBrandName: 'BINGLE',
  supportedCards: ['visa', 'mastercard', 'eftpos'],
  links: {
    policyFinderLink:
      'https://online.bingle.com.au/policyfinder/bingle/#/websites/customer',
    contactUsLink: 'https://www.bingle.com.au/contact-us.html',
    contactUsTelLink: '',
    privacyStatementLink: 'https://www.bingle.com.au/privacy.html',
  },
  contactNumber: 'Submit a request – Bingle Help Centre',
  contactUsForm:
    'https://help.bingle.com.au/hc/en-us/requests/new?ticket_form_id=14405825732249',
  copyrightStatement: '© 2015 AAI Limited trading as Bingle Insurance',
};

module.exports = BINGLE_CONSTANTS;
