import React from 'react';
import { Overlay, Spinner } from '@suncorp/styleguide-react-components';
import { useSelector } from 'react-redux';

const LoadingSpinner = () => {
  const availableLoaders = {
    defaultLoadingIndicator: (
      <Overlay>
        <Spinner id="loadingSpinner" />
      </Overlay>
    ),
  };

  const { loaderComponent } = useSelector(
    (state) => state.helpers.loadingHelper,
  );

  return availableLoaders[loaderComponent];
};

export default LoadingSpinner;
