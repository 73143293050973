import _ from 'lodash';
import { PAGE_INDEX } from '../constants/commonConstants';

export const getDataLayer = () => window.dataLayer || {};

export const getWebAnalytics = () => {
  // Prevent from breaking the app when analytics is missing
  if (!window.webAnalytics) {
    return {
      publish: () => {},
    };
  }
  return window.webAnalytics;
};

export const publishWebAnalyticsPageView = () => {
  publishWebAnalytics('pageview');
};

export const publishWebAnalytics = (data) => {
  getWebAnalytics().publish(data, getDataLayer());
};

export const updateDataLayer = (data) => {
  if (!data) {
    return;
  }
  _.merge(getDataLayer(), data);
};

const BASE_PAGE_TYPE = 'secapp:selfservice:paymentonline';
const BASE_PAGE_NAME = (siteId) => `${siteId}:${BASE_PAGE_TYPE}`;

export const getPageName = (pageIndex) => {
  switch (pageIndex) {
    case PAGE_INDEX.PRN_POLICY_DETAILS_PAGE:
      return 'payment_started';
    case PAGE_INDEX.CARD_DETAILS_PAGE:
      return 'creditcard_details';
    case PAGE_INDEX.CONFIRM_PAYMENT_DETAILS_PAGE:
      return 'creditcard_confirmation';
    case PAGE_INDEX.CONFIRMATION_PAGE:
      return 'payment_completed';
  }
  return 'unknown_path';
};

export const getAnalyticsPageName = (pageIndex) =>
  `${BASE_PAGE_NAME(getDataLayer().siteID)}:${getPageName(pageIndex)}`;

export const getAnalyticsPageType = (pageIndex) =>
  `${BASE_PAGE_TYPE}:${getPageName(pageIndex)}`;

export const resetDataLayer = () => {
  updateDataLayer({
    events: null,
    errors: null,
    productSet: null,
    dataModel: null,
    pageName: null,
    pageType: null,
  });
  updateDataLayer({
    events: [],
    errors: [],
    productSet: [],
    dataModel: {
      dataModelType: 'payonline',
      payonline: {
        selfServiceType: 'in_selfservice_payment_online',
      },
    },
  });
};

export const updateAnalyticsOnPrnPolicyDetailsRender = () => {
  const pageIndex = PAGE_INDEX.PRN_POLICY_DETAILS_PAGE;
  resetDataLayer();
  updateDataLayer({
    events: ['SELF_SERVICE_TRANSACTION_STARTED'],
    pageName: getAnalyticsPageName(pageIndex),
    pageType: getAnalyticsPageType(pageIndex),
  });
  publishWebAnalyticsPageView();
};

export const updateAnalyticsOnBusinessOrTechnicalError = (
  pageIndex,
  errorMessage,
  productSet,
  isBusinessError,
  isTechError,
) => {
  resetDataLayer();
  updateDataLayer({
    pageName: `${getAnalyticsPageName(pageIndex)}:${
      isBusinessError
        ? 'jeopardy_business'
        : isTechError
        ? 'jeopardy_technical'
        : ''
    }`,
    productSet: productSet,
    jeopardyMessages: [`${errorMessage}`],
    events: [
      `${
        isBusinessError
          ? 'JEOPARDY_BUSINESS'
          : isTechError
          ? 'JEOPARDY_TECHNICAL'
          : ''
      }`,
    ],
  });
  publishWebAnalyticsPageView();
};

export const updateAnalyticsOnPageRender = (
  pageIndex,
  prnNumber,
  policyNumber,
  paymentAmount,
  cardType,
  inRenewal,
  productSet,
  transactionDate,
  receiptNumber,
) => {
  resetDataLayer();
  updateDataLayer({
    dataModel: {
      payonline: {
        paymentReferenceNumber: prnNumber,
        policyNumber: policyNumber,
        premium: paymentAmount.toString(),
        cardType: cardType ? cardType : '',
        inRenewal: inRenewal.toString(),
        ...((pageIndex === PAGE_INDEX.CONFIRM_PAYMENT_DETAILS_PAGE ||
          pageIndex === PAGE_INDEX.CONFIRMATION_PAGE) && {
          type: 'Credit_card',
        }),
        ...(pageIndex === PAGE_INDEX.CONFIRMATION_PAGE && {
          receiptDate: transactionDate,
          receiptNumber: receiptNumber,
        }),
      },
    },
    ...(pageIndex === PAGE_INDEX.CONFIRMATION_PAGE && {
      events: ['SELF_SERVICE_TRANSACTION_COMPLETED'],
    }),
    productSet: productSet,
    pageName: getAnalyticsPageName(pageIndex),
    pageType: getAnalyticsPageType(pageIndex),
  });
  publishWebAnalyticsPageView();
};
