const VERO_CONSTANTS = {
  // Readable brand name used in the display
  brandName: 'VERO',
  // Readable brand name used in the PRNPolicyDisplay Page
  brandNameCaseSense: 'Vero Insurance',
  // Brand name used in API calls
  apiBrandName: 'VERO',
  supportedCards: ['visa', 'mastercard', 'americanexpress', 'eftpos'],
  links: {
    policyFinderLink: 'https://thishastoberemoved.com.au',
    //To open Email client
    contactUsLink: 'mailto:Claims@Vero.com.au',
    contactUsTelLink: 'tel:1300888073',
    privacyStatementLink: 'https://www.vero.com.au/online-terms-privacy.html',
  },
  contactNumber: '1300 888 073',
  copyrightStatement:
    'AAI Limited ABN 48 005 297 807 trading as Vero Insurance',
};

module.exports = VERO_CONSTANTS;
