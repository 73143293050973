import React from 'react';
import { ListItem } from '@suncorp/styleguide-react-components';
import * as PropType from 'prop-types';

const NextProgressStep = ({ displayText }) => (
  <ListItem
    className="sg-Progress-step sg-Progress-step--next sg-u-noMargin"
    data-position="next"
  >
    <span className="sg-u-visuallyhidden">Next step:</span>
    <span className="sg-Progress-text">{displayText}</span>
  </ListItem>
);

NextProgressStep.propTypes = {
  displayText: PropType.string,
};

export default NextProgressStep;
