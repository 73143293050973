import React, { useContext } from 'react';
import {
  Footer,
  Link,
  List,
  ListItem,
  Paragraph,
} from '@suncorp/styleguide-react-components';
import { BrandContext } from '../../BrandContext';

const DefaultFooter = () => {
  const brandConfig = useContext(BrandContext);
  return (
    <Footer>
      <List appearance="unstyled" className="sg-List-horizontal">
        <ListItem>
          <Link
            href={brandConfig.links.privacyStatementLink}
            target="_blank"
            className="sg-Type--linkInverse"
          >
            Online Terms &amp; Privacy Statement
          </Link>
        </ListItem>
      </List>
      <Paragraph className="sg-Type--small sg-Type--muted">
        {brandConfig.copyrightStatement}
      </Paragraph>
    </Footer>
  );
};

export default DefaultFooter;
