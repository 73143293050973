import React from 'react';
import PropTypes from 'prop-types';
import { Grid, GridRow, GridCol } from '@suncorp/styleguide-react-components';
import ProgressBarContainer from '../../molecules/ProgressBarContainer';

const PageContent = ({
  children,
  gridColLargeSpan,
  pageIndex,
  setIsPaymentError,
  setPaymentErrorMessage,
  setPaymentBusinessErrorMessage,
}) => (
  <Grid>
    <GridRow>
      <GridCol
        span={{
          default: 12,
          large: 3,
        }}
      >
        <ProgressBarContainer
          pageIndex={pageIndex}
          setIsPaymentError={setIsPaymentError}
          setPaymentErrorMessage={setPaymentErrorMessage}
          setPaymentBusinessErrorMessage={setPaymentBusinessErrorMessage}
        ></ProgressBarContainer>
      </GridCol>
      <GridCol
        span={{
          default: 12,
          large: gridColLargeSpan,
        }}
      >
        {children}
      </GridCol>
    </GridRow>
  </Grid>
);
PageContent.propTypes = {
  children: PropTypes.node.isRequired,
  gridColLargeSpan: PropTypes.number.isRequired,
  pageIndex: PropTypes.number.isRequired,
  setIsPaymentError: PropTypes.func.isRequired,
  setPaymentErrorMessage: PropTypes.func.isRequired,
  setPaymentBusinessErrorMessage: PropTypes.func.isRequired,
};

export default PageContent;
