import React from 'react';
import {
  Cell,
  Heading,
  Paragraph,
  Link,
} from '@suncorp/styleguide-react-components';
import * as PropType from 'prop-types';

const BusinessErrorCard = ({
  errorTitle,
  errorMessage,
  isPaymentError,
  brandConfig,
}) => (
  <Cell
    appearance="error"
    className="sg-u-marginTop--x2 sg-u-marginBottom--x2 sg-u-radiusSmall"
    data-testid="prn-policy-business-error"
  >
    <Heading rank={6}>{errorTitle}</Heading>
    <Paragraph>
      {errorMessage}
      {!isPaymentError && brandConfig.brandName === 'BINGLE' && (
        <>
          <Link href={brandConfig.contactUsForm} target="_blank">
            contact us
          </Link>
          {''} and our support staff will assist you.
        </>
      )}
      {!isPaymentError && brandConfig.brandName === 'VERO' && (
        <>
          <Link href={brandConfig.links.contactUsTelLink} target="_blank">
            give us a call on {brandConfig.contactNumber}
          </Link>
          {''} and one of our Claims Officers will assist you.
        </>
      )}
    </Paragraph>
    {isPaymentError ? (
      brandConfig.brandName === 'VERO' ? (
        <Paragraph>
          Check each field and try again or if you continue to have trouble,
          please{' '}
          <Link
            href={brandConfig.links.contactUsTelLink}
            target="_blank"
            className="sg-Type--linkInverse"
          >
            call us on {brandConfig.contactNumber}
          </Link>
          .
        </Paragraph>
      ) : (
        <Paragraph>
          Check each field and try again or if you continue to have trouble,
          please{' '}
          <Link href={brandConfig.links.contactUsLink} target="_blank">
            contact us
          </Link>
          .
        </Paragraph>
      )
    ) : (
      ''
    )}
  </Cell>
);

BusinessErrorCard.propTypes = {
  errorTitle: PropType.string.isRequired,
  errorMessage: PropType.string.isRequired,
  isPaymentError: PropType.bool,
  brandConfig: PropType.object,
};

export default BusinessErrorCard;
