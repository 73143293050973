import React, { useEffect, useState } from 'react';
import {
  Flextable,
  FlexCell,
  List,
  Button,
  Paragraph,
} from '@suncorp/styleguide-react-components';
import { useDispatch } from 'react-redux';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { uiInfoApi } from '../../../apis/infoApis';
import PropTypes from 'prop-types';

const TestHelper = ({ appEnv, isBetaOrProd, flags }) => {
  const [showTestHelpers, setShowTestHelpers] = useState(true);
  const [version, setVersion] = useState(null);
  const [commit, setCommit] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (showTestHelpers) {
      uiInfoApi().then((resp) => {
        setCommit(resp.git_commit);
        setVersion(resp.version);
      });
    }
  }, [showTestHelpers]);

  if (!isBetaOrProd && showTestHelpers) {
    return (
      <>
        <hr className="sg-u-noMarginBottom" />
        <Flextable columns={6}>
          <FlexCell
            contentAlign="middleCentre"
            customWidth={{ default: '5of6' }}
            data-testid="environmentDetailsTestId"
          >
            <List
              appearance="horizontalBar"
              data={[
                `UI Env: ${appEnv}`,
                `UI Version: ${version}`,
                `UI Commit: ${commit}`,
              ]}
            />
          </FlexCell>
          <FlexCell contentAlign="middleRight">
            <Button
              type="button"
              onClick={() => setShowTestHelpers(false)}
              className="sg-Btn sg-Btn--iconLeft sg-Btn--small"
            >
              Hide
              <i className="sg-Btn-icon Icon-cross--dark Icon--small" />
            </Button>
          </FlexCell>
        </Flextable>
        <div className="sg-u-margin">
          <div>
            <Button
              onClick={() => dispatch.navigationProgress.setIsTechError(true)}
            >
              Trigger Tech Error
            </Button>
          </div>
          <Paragraph className="sg-u-noMargin sg-Type--bold">
            LaunchDarkly Feature Flags
          </Paragraph>
          {Object.keys(flags).length === 0 ? (
            <Paragraph>No feature flags configured</Paragraph>
          ) : (
            <>
              {Object.keys(flags).map((key) => (
                <span key={key}>
                  <Paragraph
                    key={key}
                    className="sg-u-noMargin sg-u-breakWord"
                  >{`${key}: ${
                    flags[key] ? 'ENABLED' : 'DISABLED'
                  }`}</Paragraph>
                </span>
              ))}
            </>
          )}
        </div>
      </>
    );
  }
  return <></>;
};

TestHelper.propTypes = {
  appEnv: PropTypes.string,
  isBetaOrProd: PropTypes.bool,
  flags: PropTypes.object,
};

export default withLDConsumer()(TestHelper);
