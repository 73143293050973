import React from 'react';
import { Link } from '@suncorp/styleguide-react-components';
import { PAGE_INDEX } from '../../../utils/constants/commonConstants';
import { useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import CommonProgressBarOrderedList from '../CommonProgressBarOrderedList';
import CurrentProgressStep from '../CurrentProgressStep';
import NextProgressStep from '../NextProgressStep';
import CompleteProgressStep from '../CompleteProgressStep';

const ProgressBarContainer = ({
  pageIndex,
  setIsPaymentError,
  setPaymentErrorMessage,
  setPaymentBusinessErrorMessage,
}) => {
  const dispatch = useDispatch();
  switch (pageIndex) {
    case PAGE_INDEX.PRN_POLICY_DETAILS_PAGE:
      return (
        <CommonProgressBarOrderedList
          completionRate="0"
          id="progressBars-0complete"
        >
          <CurrentProgressStep displayText="Your details"></CurrentProgressStep>
          <NextProgressStep displayText="Payment details"></NextProgressStep>
          <NextProgressStep displayText="Confirm your payment"></NextProgressStep>
        </CommonProgressBarOrderedList>
      );
    case PAGE_INDEX.CARD_DETAILS_PAGE:
      return (
        <CommonProgressBarOrderedList
          completionRate="33"
          id="progressBars-33complete"
        >
          <CompleteProgressStep>
            <Link
              href="#!"
              role="button"
              className="sg-Progress-link"
              data-testid="your-details-page2"
              onClick={() => {
                dispatch.navigationProgress.setPageIndex(
                  PAGE_INDEX.PRN_POLICY_DETAILS_PAGE,
                );
                // Clear errors.
                setIsPaymentError(false);
                setPaymentErrorMessage('');
                setPaymentBusinessErrorMessage('');
              }}
            >
              Your details
            </Link>
          </CompleteProgressStep>
          <CurrentProgressStep displayText="Payment details"></CurrentProgressStep>
          <NextProgressStep displayText="Confirm your payment"></NextProgressStep>
        </CommonProgressBarOrderedList>
      );
    case PAGE_INDEX.CONFIRM_PAYMENT_DETAILS_PAGE:
      return (
        <CommonProgressBarOrderedList
          completionRate="66"
          id="progressBars-66complete"
        >
          <CompleteProgressStep>
            <Link
              href="#!"
              role="button"
              className="sg-Progress-link"
              data-testid="your-details-page3"
              onClick={() => {
                // Clear the cardDetails state.
                dispatch.cardAndPaymentDetails.setCardDetails({});
                dispatch.navigationProgress.setPageIndex(
                  PAGE_INDEX.PRN_POLICY_DETAILS_PAGE,
                );
                // Clear errors.
                setIsPaymentError(false);
                setPaymentErrorMessage('');
                setPaymentBusinessErrorMessage('');
              }}
            >
              Your details
            </Link>
          </CompleteProgressStep>
          <CompleteProgressStep>
            <Link
              href="#!"
              role="button"
              className="sg-Progress-link"
              data-testid="payment-details"
              onClick={() => {
                // Clear the cardDetails state.
                dispatch.cardAndPaymentDetails.setCardDetails({});
                dispatch.navigationProgress.setPageIndex(
                  PAGE_INDEX.CARD_DETAILS_PAGE,
                );
              }}
            >
              Payment details
            </Link>
          </CompleteProgressStep>
          <CurrentProgressStep displayText="Confirm your payment"></CurrentProgressStep>
        </CommonProgressBarOrderedList>
      );
    default:
      return null;
  }
};

ProgressBarContainer.propTypes = {
  pageIndex: PropTypes.number.isRequired,
  setIsPaymentError: PropTypes.func.isRequired,
  setPaymentErrorMessage: PropTypes.func.isRequired,
  setPaymentBusinessErrorMessage: PropTypes.func.isRequired,
};

export default ProgressBarContainer;
