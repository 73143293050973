import React, { useContext, useEffect, useState } from 'react';
import { Heading, List, ListItem } from '@suncorp/styleguide-react-components';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import PRNPolicyDetailsForm from '../../forms/PRNPolicyDetailsForm';
import { PAGE_INDEX } from '../../../utils/constants/commonConstants';
import CardLogoContainer from '../../molecules/CardLogoContainer';
import { BrandContext } from '../../organisms/BrandContext';
import {
  getBusinessErrors,
  VALIDATION_ERROR_TITLE,
} from '../../../utils/constants/businessErrorConstants';
import {
  updateAnalyticsOnBusinessOrTechnicalError,
  updateAnalyticsOnPrnPolicyDetailsRender,
} from '../../../utils/analytics/dataLayerUtils';
import { TECH_ERROR_TYPE } from '../../organisms/CardCaptureComponent/utils/constants';

const PRNPolicyDetailsPage = () => {
  const dispatch = useDispatch();
  const [prnNumber, email, productDetails] = useSelector((state) => [
    state.policyDetails.prnNumber,
    state.policyDetails.email,
    state.policyDetails.productDetails,
  ]);
  const brandConfig = useContext(BrandContext);
  const [isBusinessError, setIsBusinessError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [businessErrorDescription, setBusinessErrorDescription] = useState('');
  const [techErrorMessage, setTechErrorMessage] = useState('');

  const fetchPolicyDetails = async () => {
    try {
      return await dispatch.cardAndPaymentDetails.makeCallToPolicyDetailsAPI(
        brandConfig.apiBrandName,
      );
    } catch (error) {
      return error.response;
    }
  };

  const hideLoadingSpinner = () => {
    dispatch.helpers.setLoading({
      isLoading: false,
      loaderComponent: '',
    });
  };

  const handleSubmit = (values) => {
    dispatch.policyDetails.setPRNNumber(values.prnPolicyNumber);
    dispatch.policyDetails.setEmail(values.emailAddress);

    const callPolicyDetailsApiWithSpinner = async () =>
      dispatch.helpers.callWithLoader({
        asyncCallbackFunction: fetchPolicyDetails,
        loaderComponent: 'defaultLoadingIndicator',
        hideSpinnerAfterSuccess: false,
      });
    callPolicyDetailsApiWithSpinner().then((response) => {
      // When the response returns as 500 or more
      if (response && response.status >= 400) {
        // Show tech error page, then hide the loading spinner
        setTechErrorMessage(
          response && response.data && response.data.errors
            ? response.data.errors[0].title
            : TECH_ERROR_TYPE.APP_NOT_RESPONDING_ERR,
        );
        dispatch.navigationProgress.setIsTechError(true);
        hideLoadingSpinner();
      } // When the response returns between 400 and 500
      else if (
        response &&
        response.policyDetailsResponse &&
        response.policyDetailsResponse.errorDetails &&
        Object.entries(response.policyDetailsResponse.errorDetails).length !== 0
      ) {
        // When the response returns with error code as POLICY_PRN_NOT_FOUND
        if (
          response.policyDetailsResponse.errorDetails.code ===
          VALIDATION_ERROR_TITLE.POLICY_PRN_NOT_FOUND
        ) {
          setErrorMessage(
            getBusinessErrors(brandConfig, values.prnPolicyNumber)
              .POLICY_OR_PRN_NOT_FOUND,
          );
          setIsBusinessError(true);
          setBusinessErrorDescription(
            response.policyDetailsResponse.errorDetails.description,
          );
          hideLoadingSpinner();
        } // When the response returns with error code as POLICY_PRN_NOT_VALID
        else if (
          response.policyDetailsResponse.errorDetails.code ===
          VALIDATION_ERROR_TITLE.POLICY_PRN_NOT_VALID
        ) {
          setErrorMessage(
            getBusinessErrors(brandConfig, values.prnPolicyNumber)
              .POLICY_OR_PRN_NOT_FOUND,
          );
          setIsBusinessError(true);
          setBusinessErrorDescription(
            response.policyDetailsResponse.errorDetails.description,
          );
          hideLoadingSpinner();
        } // When the response returns with error code as NO_OUTSTANDING_AMOUNT_PRN
        else if (
          response.policyDetailsResponse.errorDetails.code ===
          VALIDATION_ERROR_TITLE.NO_OUTSTANDING_AMOUNT_PRN
        ) {
          setErrorMessage(
            getBusinessErrors(brandConfig, values.prnPolicyNumber)
              .NO_OUTSTANDING_AMOUNT,
          );
          setIsBusinessError(true);
          setBusinessErrorDescription(
            response.policyDetailsResponse.errorDetails.description,
          );
          hideLoadingSpinner();
        } // When the response returns with error code as NO_OUTSTANDING_AMOUNT_POLICY_NUMBER
        else if (
          response.policyDetailsResponse.errorDetails.code ===
          VALIDATION_ERROR_TITLE.NO_OUTSTANDING_AMOUNT_POLICY_NUMBER
        ) {
          setErrorMessage(
            getBusinessErrors(brandConfig, values.prnPolicyNumber)
              .NO_OUTSTANDING_AMOUNT,
          );
          setIsBusinessError(true);
          setBusinessErrorDescription(
            response.policyDetailsResponse.errorDetails.description,
          );
          hideLoadingSpinner();
        } // When the response returns with error code as POLICY_CANCELLED
        else if (
          response.policyDetailsResponse.errorDetails.code ===
          VALIDATION_ERROR_TITLE.POLICY_CANCELLED
        ) {
          setErrorMessage(
            getBusinessErrors(brandConfig, values.prnPolicyNumber)
              .POLICY_OR_PRN_NOT_FOUND,
          );
          setIsBusinessError(true);
          setBusinessErrorDescription(
            response.policyDetailsResponse.errorDetails.description,
          );
          hideLoadingSpinner();
        }
        // When the response returns with error code as POLICY_EXPIRED
        else if (
          response.policyDetailsResponse.errorDetails.code ===
          VALIDATION_ERROR_TITLE.POLICY_EXPIRED
        ) {
          setErrorMessage(
            getBusinessErrors(brandConfig, values.prnPolicyNumber)
              .POLICY_OR_PRN_NOT_FOUND,
          );
          setIsBusinessError(true);
          setBusinessErrorDescription(
            response.policyDetailsResponse.errorDetails.description,
          );
          hideLoadingSpinner();
        } // When the response doesn't exactly match the 4xx status' above, but is still between 400 and 500
        else {
          // Show tech error page, then hide the loading spinner
          dispatch.navigationProgress.setIsTechError(true);
          setTechErrorMessage(
            response.policyDetailsResponse.errorDetails.description,
          );
          hideLoadingSpinner();
        }
      } // When the response is successful
      else if (response && response.policyDetailsResponse) {
        // Set the digitalPaymentId for us in subsequent requests
        dispatch.helpers.setDigitalPaymentId(
          response.policyDetailsResponse.digitalPaymentId,
        );
        // Set the policy or claim details
        dispatch.policyDetails.setPRNNumber(
          response.policyDetailsResponse.paymentReferenceNumber,
        );
        dispatch.policyDetails.setPolicyNumber(
          response.policyDetailsResponse.policyNumber,
        );
        dispatch.policyDetails.setTypeOfPrn(
          response.policyDetailsResponse.typeofPRN,
        );
        dispatch.policyDetails.setProductDetails(
          response.policyDetailsResponse.productDetails,
        );
        dispatch.policyDetails.setInRenewal(
          response.policyDetailsResponse.inRenewal,
        );
        dispatch.cardAndPaymentDetails.setPaymentAmount(
          response.policyDetailsResponse.amount,
        );
        dispatch.cardAndPaymentDetails.setAmountEditable(
          response.policyDetailsResponse.amountEditable,
        );
        // Go to the next page
        dispatch.navigationProgress.setPageIndex(PAGE_INDEX.CARD_DETAILS_PAGE);
      } // When there are any other errors not caught above
      else {
        // Show tech error page, then hide the loading spinner
        setTechErrorMessage(
          response && response.data && response.data.errors
            ? response.data.errors[0].title
            : TECH_ERROR_TYPE.APP_NOT_RESPONDING_ERR,
        );
        dispatch.navigationProgress.setIsTechError(true);
        hideLoadingSpinner();
      }
    });
  };

  // Update dataLayer and publish pageview
  useEffect(() => {
    // Update analytics only once DOM has fully loaded
    const onPageLoad = () => {
      updateAnalyticsOnPrnPolicyDetailsRender();
    };

    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad, false);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  // Update dataLayer when error occurs and publish pageview
  useEffect(() => {
    const errDetails = businessErrorDescription || techErrorMessage;
    const isTechError = !!techErrorMessage;
    // Update analytics when error updates
    if (isBusinessError || isTechError) {
      updateAnalyticsOnBusinessOrTechnicalError(
        PAGE_INDEX.PRN_POLICY_DETAILS_PAGE,
        errDetails,
        productDetails,
        isBusinessError,
        isTechError,
      );
    }
  }, [
    businessErrorDescription,
    isBusinessError,
    productDetails,
    techErrorMessage,
  ]);

  const getInitialPrn = () => {
    // Get paymentReferenceNumber from URL params
    const params = new URLSearchParams(window.location.search);
    const paymentReferenceNumberParam = params.get('paymentReferenceNumber')
      ? params.get('paymentReferenceNumber')
      : params.get('ref');
    // Return the param value if:
    // - paymentReferenceNumber param exists in the URL AND
    // - prnNumber in redux is in its initial state AND
    // - paymentReferenceNumber param only contains letters and numbers
    // Otherwise return prnNumber from state
    return paymentReferenceNumberParam &&
      prnNumber.trim().length === 0 &&
      paymentReferenceNumberParam.match(/^[a-zA-Z0-9]*$/) !== null
      ? paymentReferenceNumberParam
      : prnNumber;
  };
  const veroBrand = brandConfig.brandName === 'VERO';
  const howToPay = veroBrand
    ? [
        `To pay an excess or claims related payment, use your Payment Reference Number (PRN). This number
          can be found on the notice received from ${brandConfig.brandNameCaseSense} requesting payment.`,
      ]
    : [
        `To pay your premium, use either your policy number or Payment Reference Number (PRN).`,
        `To pay an excess or claims related payment, use your Payment Reference Number (PRN). This number
          can be found on the notice received from
          ${brandConfig.brandNameCaseSense} requesting payment.`,
      ];

  return (
    <section id="prn-policy-details-section">
      <Heading rank={1} className="sg-Type--heading2">
        The fast, easy and secure way to pay online
      </Heading>
      <Formik
        initialValues={{
          prnPolicyNumber: getInitialPrn(),
          emailAddress: email,
        }}
        enableReinitialize
        onSubmit={(values) => handleSubmit(values)}
      >
        {(formikProps) => (
          <PRNPolicyDetailsForm
            isBusinessError={isBusinessError}
            errorMessage={errorMessage}
            {...formikProps}
          />
        )}
      </Formik>
      <Heading rank={3} className="sg-u-marginTop--x4 sg-Type--heading3">
        How to pay
      </Heading>
      <List>
        {howToPay.map((item, idx) => (
          <ListItem key={idx}>{item}</ListItem>
        ))}
      </List>
      <CardLogoContainer />
    </section>
  );
};

export default PRNPolicyDetailsPage;
