export const navigationProgressInitialState = {
  currentPage: 1,
  isTechError: false,
  isSessionTimeout: false,
  isPaymentTimeout: false,
};

export const navigationProgressModel = {
  state: navigationProgressInitialState,
  reducers: {
    setPageIndex(state, index) {
      return { ...state, currentPage: index };
    },
    setIsTechError(state, value) {
      return { ...state, isTechError: value };
    },
    setIsSessionTimeout(state, value) {
      return { ...state, isSessionTimeout: value };
    },
    setIsPaymentTimeout(state, value) {
      return { ...state, isPaymentTimeout: value };
    },
    resetNavigationProgress(state) {
      return { ...state, ...navigationProgressInitialState };
    },
  },
};

export default navigationProgressModel;
