import React from 'react';
import {
  GridCol,
  GridRow,
  Link,
  Heading,
} from '@suncorp/styleguide-react-components';
import * as PropType from 'prop-types';
import CommonGridRow from '../CommonGridRow';
import { PAGE_INDEX } from '../../../utils/constants/commonConstants';
import { useDispatch } from 'react-redux';
import { isPolicyNumber } from '../../../utils/validators';

const PolicyDetails = ({ prnNumber, policyNumber, email, typeOfPrn }) => {
  const dispatch = useDispatch();
  return (
    <>
      <GridRow spacing="around" className="sg-u-paddingBottom">
        <GridCol
          span={{
            default: 8,
            large: 8,
            small: 8,
            xlarge: 8,
          }}
        >
          <Heading className="sg-Type--heading6" rank={2}>
            Payment and contact details
          </Heading>
        </GridCol>
        <GridCol
          className="sg-u-flex--top sg-u-flex--right"
          span={{
            default: 4,
            large: 4,
            small: 4,
            xlarge: 4,
          }}
        >
          <Link
            href="#!"
            className="sg-Type--colourSecondary sg-Type--iconText Icon-edit--secondary"
            data-testid="prn-edit"
            onClick={() =>
              dispatch.navigationProgress.setPageIndex(
                PAGE_INDEX.PRN_POLICY_DETAILS_PAGE,
              )
            }
          >
            Edit
          </Link>
        </GridCol>
      </GridRow>
      <CommonGridRow
        sectionName={
          isPolicyNumber(prnNumber, policyNumber, typeOfPrn)
            ? 'Policy number'
            : 'Payment Reference Number (PRN)'
        }
        value={prnNumber}
      ></CommonGridRow>
      <CommonGridRow sectionName="Email address" value={email}></CommonGridRow>
    </>
  );
};

PolicyDetails.propTypes = {
  prnNumber: PropType.string,
  policyNumber: PropType.string,
  email: PropType.string,
  typeOfPrn: PropType.string,
};

export default PolicyDetails;
