import DOMPurify from 'dompurify';
import { STYLE_TYPE, TARGET_ORIGIN } from './constants';
import { SG_BRAND_NAME } from '../../../../utils/constants/commonConstants';
import { v4 as uuidV4 } from 'uuid';

// Function to add a listener for the messages received from child frame via 'postMessage'.
export const addMessageListener = (callback = null) => {
  if (typeof callback !== 'function') return;
  window.addEventListener('message', callback);
};

// Function to iterate through and set all the given attributes to the passed DOM element.
export const setElemAttributes = (elem = {}, attrs = {}) => {
  if (!attrs || !elem) return;
  Object.entries(attrs).forEach(([k, attr]) => {
    elem.setAttribute(k, attr);
  });
};

// Function to sanitize, clean the data from the given postMessage API event.
export const getSanitizedData = (event = {}) => {
  // The default data needs to be a valid 'object'.
  const defaultData = {};

  try {
    const sourceData = JSON.stringify(event.data);
    const parsedData = JSON.parse(DOMPurify.sanitize(sourceData));
    return parsedData || defaultData;
  } catch (error) {
    return defaultData;
  }
};

// Function to check if the 'postMessage' is available in the child frame or not.
export const canMessageChild = (frame = {}) => {
  // Frame's content window should exist.
  const { contentWindow } = frame || {};
  if (!contentWindow) {
    return false;
  }

  // The 'postMessage 'API should exist.
  const { postMessage } = contentWindow;
  if (typeof postMessage !== 'function') {
    return false;
  }

  // Default.
  return true;
};

// Function to post message to child frame with the given payload.
export const postMessageToChild = (frame = {}, mType = '', payload = {}) => {
  // Only proceed if postMessage API exists in content window.
  if (!canMessageChild(frame)) {
    return;
  }
  // Post message to child frame.
  const { contentWindow } = frame;
  const message = { type: mType, payload };
  contentWindow.postMessage(message, TARGET_ORIGIN);
};

// Function to get iframe context.
export const getContext = (paymentAmount, amountEditable, brandConfig) => ({
  amount: parseFloat(paymentAmount) || 0,
  brand: brandConfig.apiBrandName,
  brandStyle: SG_BRAND_NAME,
  clientId: 'PayonlineUI',
  correlationId: uuidV4(),
  enhancedUI: true,
  paymentAmtConfig: {
    isDisabled: !amountEditable,
    minValue: amountEditable ? 0.01 : parseFloat(paymentAmount) || 0,
    maxValue: amountEditable ? 9999999999 : parseFloat(paymentAmount) || 0,
  },
  paymentGateway: 'BPGS',
  product: 'NA',
  showPaymentAmt: true,
  showSecurityCode: true,
  showSupportedCards: true,
  styleType: STYLE_TYPE.BRANDED,
  supportedCards: {
    hasAmex: brandConfig.supportedCards.includes('americanexpress'),
    hasEftpos: brandConfig.supportedCards.includes('eftpos'),
    hasMastercard: brandConfig.supportedCards.includes('mastercard'),
    hasVisa: brandConfig.supportedCards.includes('visa'),
  },
});
